import React from 'react'
import { graphql } from 'gatsby'
import contentParser from 'gatsby-wpgraphql-inline-images'
import Image from 'gatsby-image'

import Layout from '../components/Layout'
import { H1, H2 } from '../components/Headings'
import Container from '../components/Container'
import tw from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { ButtonLink } from '../components/Button'
import PostContent from '../components/Post/Content'
import PostMetaContent from '../components/Post/MetaContent'
import CategoryNav from '../components/Post/CategoryNav'

const PostTemplate = ({ data, preview }) => {

    const page = data?.wpPost;
    const previewPage = Array.isArray(preview?.revisions && preview?.revisions?.length > 0)
        ? preview.revisions[0] 
        : preview?.post;

    const { 
        title, 
        content, 
        postCategories, 
        featuredImage,
        date: dateString
    } = previewPage || page;

    return (
        <Layout>
            <main>
                <Container tw="grid mt-10 md:(grid-cols-3) lg:(grid-cols-4) xl:(grid-cols-5) gap-8">

                    {/* Sticky Categories Nav */}

                    <div tw="h-full relative">
                        <CategoryNav />
                    </div>

                    {/* Article */}
                    <div aria-label="article" tw="md:(col-span-2) lg:(grid-cols-3) xl:(col-span-4)">
                        <div aria-label="article">

                            <PostMetaContent publishedDate={dateString} categories={postCategories} />
                            <H1 tw="text-5xl text-gray-800 font-bold">{title}</H1>
                            {featuredImage?.node?.localFile ? (
                                <Image
                                    fluid={featuredImage.node.localFile.childImageSharp.fluid} 
                                    tw="w-full mb-5"
                                />
                            ) : (<img tw="w-full mb-5" src={featuredImage?.node?.sourceUrl} alt="" />)}
                            <PostContent 
                                tw="text-xl text-gray-700 leading-relaxed"
                            >
                                { contentParser({ content }, {
                                    wordPressUrl: process.env.GATSBY_WP_BASE_URL,
                                    uploadsUrl: `${process.env.GATSBY_WP_BASE_URL}/apps/uploads`
                                })}
                            </PostContent>
                        </div>
                    </div>
                </Container>
            </main>


      {/* Footer */}
      <section>
        <Container tw="text-center content-center">
          <H2 tw="text-5xl">You Don't Have to live with Pests.</H2>
          <p tw="text-lg leading-relaxed text-gray-600 lg:(px-10 mx-10) xl:(px-24 mx-24)">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <ButtonLink tw="mt-10" to="/quote" large>
            <span tw="mr-3">Get a Quote</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </ButtonLink>
        </Container>
      </section>
    </Layout>
  )
}


export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      content
      postCategories: categories {
        nodes {
          id
          slug
          name
        }
      }
      date
      title
      ...FeaturedPostImage
    }
  }
`


export default PostTemplate;

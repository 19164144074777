import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { H3 } from '../Headings'
import { ALink } from '../A'
import tw from 'twin.macro'

const CategoryNav = ({ currentCategory }) => {
  const data = useStaticQuery(graphql`
    {
      allWpCategory {
        edges {
          node {
            name
            slug
            featuredImage
          }
        }
      }
    }
  `)

  const categories = data.allWpCategory.edges

  return (
    <nav
      aria-label="Categories"
      tw="flex flex-col leading-relaxed top-0 md:(sticky)"
    >
      <H3 tw="text-2xl mb-3">Categories</H3>
      <ol>
        <div tw="grid grid-cols-2 gap-2 md:(flex flex-col)">
          {categories.map(({ node: { name, slug, featuredImage } }) => (
            <li>
              <ALink
                css={[
                  tw`flex gap-4 text-blue-600 content-center text-lg no-underline hover:(underline) capitalize mb-3`,
                  slug === currentCategory && tw`font-bold`,
                ]}
                to={`/learn/blog/category/${slug}`}
              >
                <div tw="flex justify-center content-center w-6 h-6">
                  <img src={featuredImage} />
                </div>
                {name}
              </ALink>
            </li>
          ))}
        </div>
      </ol>
    </nav>
  )
}

CategoryNav.defaultProps = {
  currentCategory: null,
}

export default CategoryNav

export const formatBranch = (previewData) => {
  const { id, slug, title, content } = previewData;

  // handle if the data is a service area rather than a branch
  const { branchMeta, branchTypes, } = previewData?.serviceAreaMeta
    ? previewData.serviceAreaMeta.serviceAreaBranch[0]
    : previewData;

  const serviceAreas = Array.isArray(branchMeta?.serviceAreas) 
    ? branchMeta.serviceAreas.map(node => node.title)
    : null;

  const branchTypeSlugs = Array.isArray(branchTypes?.nodes)
    ? branchTypes.nodes.map(node => node.slug)
    : null;

  // recurrision on revisions nodes. should only go a single level.
  const revisions = Array.isArray(previewData?.revisions?.nodes)
    ? previewData.revisions.nodes.map(formatBranch)
    : null;

  // format as an object matching the context on `service_branch.js`.
  return ({
    id,
    slug,
    title,
    content,
    featuredImage: previewData.featuredImage
      ? previewData.featuredImage.node.sourceUrl
      : null,
    branchTitle: title,
    ...branchMeta,
    serviceAreas,
    branchTypes: branchTypeSlugs,
    revisions
  })
}

export const formatPest = (previewData) => {
    const pest = previewData;

    // recurrision on revisions nodes. should only go a single level.
    const revisions = Array.isArray(previewData?.revisions)
        ? previewData.revisions.nodes.map(formatPest)
        : null;

    return ({
        pest,
        revisions,
    })

}

export const formatPost = (previewData) => {
    const post = previewData;

    // recurrision on revisions nodes. should only go a single level.
    const revisions = Array.isArray(previewData?.revisions)
        ? previewData.revisions.nodes.map(formatPost)
        : null;
    
    return ({
        ...post,
        revisions,
    })
}
import React from 'react'
import { dateFormat } from '../../helpers'
import tw from 'twin.macro'
import A from '../A'

const MetaContent = ({ publishedDate, categories }) => (
  <div tw="mb-4 text-gray-600">
    <time>{dateFormat(publishedDate)}</time>

    {Array.isArray(categories) && categories.length ? (
      <span tw="ml-4">
        Published In:{' '}
        {categories.map(({ slug, name }, index) => {
          const last = index === categories.length - 1
          return (
            <>
              <A href={`/blog/categories/${slug}`}>{name}</A>
              {!last && ', '}
            </>
          )
        })}
      </span>
    ) : null}
  </div>
)

export default MetaContent

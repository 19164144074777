import { gql } from 'apollo-boost'

export const PREVIEW_QUERY = gql`
    query previewQuery($id: ID!) {
        post(id: $id, idType: DATABASE_ID) {
            ...PostPreview
            revisions {
                nodes {
                    ...PostPreview
                }
            }
        }

        # PEST QUERY
        pest(id: $id, idType: DATABASE_ID) {
            ...PestPreview
            revisions (first: 1) {
                nodes {
                    ...PestPreview
                }
            }
        }

        branch(id: $id, idType: DATABASE_ID) {
            ...BranchPreview
            revisions (first: 1) {
                nodes {
                    ...BranchPreview
                }
            }
        }
        
        serviceArea(id: $id, idType: DATABASE_ID) {
            ...ServiceAreaPreview
            revisions (first: 1) {
                nodes {
                    ...ServiceAreaPreview
                }
            }
        }
    }

    fragment PostPreview on Post {
        id
        content
        postCategories: categories {
            nodes {
                id
                slug
                name
            }
        }
        date
        title
        featuredImage {
            node {
                sourceUrl
            }
        }
    }

    fragment PestPreview on Pest {
        id
        title
        featuredImage {
            node {
            sourceUrl
            }
        }
        pestDetails: pests {
            prevention
            fieldGroupName
            genus
            habits
            introduction
        }
        pestTypeDetails: pestTypes {
            nodes {
            description
            slug
            name
            }
        }
    }

    fragment BranchPreview on Branch {
        id
        title
        slug
        uri
        content
        featuredImage {
            node {
            id
            sourceUrl
            }
        }
        branchMeta {
            serviceAreas: branchServiceAreas {
                ... on ServiceArea {
                    id
                    title
                }
            }
            ...BranchLocationInfo
        }
    }

    fragment ServiceAreaPreview on ServiceArea {
        id
        title
        slug
        uri
        content
        featuredImage {
            node {
            id
            sourceUrl
            }
        }
        serviceAreaMeta {
            serviceAreaBranch {
                ... on Branch {
                    ...BranchPreview
                }
            }
        }
    }

    fragment BranchLocationInfo on Branch_Branchmeta {
        careerCode: branchCareerCode
        officeAddress: branchOfficeAddress
        officeCity: branchOfficeCity
        officeState: branchOfficeState
        officeZip: branchOfficeZip
        officeEmailAddress: branchOfficeEmailAddress
        officePhone: branchOfficePhone
        officeHours: branchOfficeHours
        careerCodes:branchCareerCode
    }

`
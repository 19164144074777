import React from 'react';
import withPreview from './withPreview';
import {PREVIEW_QUERY} from './preview.queries';
import { formatBranch, formatPest, formatPost } from './utils';
import tw from 'twin.macro';
import { H1 } from '../../components/Headings';
import Container from '../../components/Container';
import PostTemplate from '../post'
import PestTemplate from '../pest-template';
import BranchTemplate from '../service_branch';


const RoutePreview = (props) => {

    const previewData = {};

    if (props?.previewData?.pest) {
        Object.assign(previewData, formatPest(props.previewData.pest));
        return <PestTemplate preview={formatPest(props.preview.pest)}  {...props} />
    } else if (props?.previewData?.post) {
        Object.assign(previewData, formatPost(props.previewData.post));
        return <PostTemplate preview={formatPost(props.preview.post)} {...props}  />
    } else if (props.previewData?.serviceArea || props.previewData?.branch) {
        const branchData = props?.previewData?.serviceArea ?? props?.previewData?.branch;
        Object.assign(previewData, formatBranch(branchData));
        return <BranchTemplate preview={formatBranch(branchData)} {...props}  />
    } 

    return(
        <Container>
            <H1>No Preview Available</H1>
            <div tw="my-10 overflow-x-hidden hover:(overflow-x-scroll)">
                <pre>{JSON.stringify(previewData, null, 2)}</pre>
            </div>
        </Container>
    )
}

export default withPreview({ preview: PREVIEW_QUERY })(RoutePreview)